import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import SmallHeroSection from "../components/SmallHeroSection"
import DecorativeSection from "../components/DecorativeSection"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import { BsFillTelephoneFill, BsPhone } from "react-icons/bs"

const query = graphql`
  {
    zdrowykoziol: file(name: { eq: "zdrowykoziol2" }) {
      base
      childImageSharp {
        gatsbyImageData(formats: WEBP, placeholder: BLURRED)
      }
    }

    joanna_zdrowykoziol: file(name: { eq: "joanna_zdrowykoziol2" }) {
      base
      childImageSharp {
        gatsbyImageData(formats: WEBP, placeholder: BLURRED)
      }
    }

    logo_koziol: file(name: { eq: "logo-koziol" }) {
      publicURL
    }
  }
`

const NaszDietetykPage = () => {
  const data = useStaticQuery(query)
  return (
    <Layout page="nasz-dietetyk">
      <Seo title="Dietetyk" />
      <SmallHeroSection />
      <DecorativeSection />

      <section id="dietician" className="dietician">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="dietician-img">
                <GatsbyImage
                  image={data.zdrowykoziol.childImageSharp.gatsbyImageData}
                  alt="ZDROWY KOZIOŁ"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="dietician-desc col-lg-6">
              <h1>ZDROWY KOZIOŁ</h1>

              <h5>
                „(…)W większości najprostsze pomysły są najlepsze i
                najzdrowsze.”
              </h5>

              <p>
                Absolwent Małopolskiej Wyższej Szkoły im. Józefa Dietla w
                Krakowie na kierunku Dietetyka i Żywienie Człowieka.<br></br>{" "}
                Uczestnik szkoleń z zakresu żywienia, treningu, suplementacji.
                <br></br>
                Trener personalny oraz instruktor kulturystyki.<br></br>
                Miłośnik treningu o charakterze siłowym. Entuzjasta
                elastycznego, zdroworozsądkowego podejścia do diety.
              </p>

              <p class="quote">
                „Nie lubię kombinować w kuchni. W większości najprostsze pomysły
                są najlepsze i najzdrowsze. Uwielbiam przebywać w kuchni, jednak
                zdaję sobie sprawę, że wielu z Nas nie ma dużo czasu na
                gotowanie, dlatego w moich dietach posiłki są szybkie, smacznie,
                proste oraz dostosowane do potrzeb klienta”
              </p>
              <p class="quote">
                „Mocno wierzę w to, że dieta połączona z prawidłowym treningiem
                jest skutecznym lekarstwem w walce z wieloma chorobami
                cywilizacyjnymi. Plan żywieniowy ma być pierwszym wielkim
                krokiem w nauce zdrowego żywienia oraz zmiany nawyków
                żywieniowych.”
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="container logo-box">
        <a href="https://zdrowykoziol.com/" target="_blank">
          <img
            className="logo-koziol"
            src={data.logo_koziol.publicURL}
            alt="Zdrowy Kozioł"
          ></img>
        </a>
      </div> */}

      <section id="dietician" className="dietician">
        <div className="container">
          <div className="row">
            <div className="dietician-desc col-lg-6">
              <h1>JOANNA KOZIOŁ</h1>

              <h5>„(…)Wysiłek fizyczny daje mi spokój psychiczny”</h5>

              <p>
                Dietetyk i trener personalny, instruktorka rekreacji ruchowej ze
                specjalnością fitness oraz specjalizacjami m.in. Body Ball ,
                PUMP&SHAPE, TRX, TABATA. Praca jako dietetyk w ośrodkach zdrowia
                oraz dietetyk i trener w siłowni.
              </p>

              <p class="quote">
                „ Jestem absolwentką studiów „Dietetyki” w Krakowie. Czynnie
                uczestniczę w prelekcjach, szkoleniach i wykładach o tematyce
                odpowiedniego odżywiania i kształtowania sylwetki. Biorę udział
                w zawodach sylwetkowych (ostatnio IV miejsce w Uniwersyteckich
                Mistrzostwach Polski PZKFiTS w Kulturystyce i Fitness w Krakowie
                2016r oraz VI miejsce w Kulturystycznych zawodach Sopockich ).”
              </p>
              <p class="quote">
                „Tematyka z zakresu odpowiedniego żywienia nie jest mi obca.
                Wykonując doświadczenia z zakresu żywienia oraz treningu również
                na sobie sprawdzam jakie alternatywy okazują się być
                najskuteczniejsze w drodze do wymarzonego ciała. W pracy zależy
                mi na tym aby osoby współpracujące czerpały nie tylko korzyści
                fizyczne ale przede wszystkim psychiczne.”
              </p>
            </div>

            <div className="col-lg-6">
              <div className="dietician-img">
                <GatsbyImage
                  image={
                    data.joanna_zdrowykoziol.childImageSharp.gatsbyImageData
                  }
                  // alt={data.joanna_zdrowykoziol.base}
                  className="img-fluid"
                  alt="Joanna Kozioł - Dietetyk"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NaszDietetykPage
